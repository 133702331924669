$sizes: (
  "sm": (
    "fontSize": "sm",
    "paddingY": "[7px]",
    "paddingX": "[15px]",
  ),
  "base": (
    "fontSize": "sm",
    "paddingY": "[9px]",
    "paddingX": "[15px]",
  ),
  "lg": (
    "fontSize": "sm",
    "paddingY": "[14px]",
    "paddingX": "[15px]",
  ),
);
$input-px: 15;
$input-py: 9;

@layer base {
  @mixin baseInput() {
    @apply appearance-none;
    @apply text-sm placeholder:text-neutral-500;
    @apply border border-neutral-200 bg-white;
    @apply rounded-[4px] px-[15px] py-[9px];
    @apply hover:outline hover:outline-2 hover:-outline-offset-2 hover:outline-neutral-200;
    @apply focus-visible:rounded-[0.063rem] focus-visible:outline-blue-400;
    @apply hover:rounded-[4px];
    @apply disabled:bg-neutral-100 disabled:outline-0;
    @apply read-only:outline-neutral-200;
    @apply invalid:border-rose-600 invalid:outline-rose-600;
    @apply inline-flex items-start justify-start self-stretch;
  }
  .input-field {
    @include baseInput();
    &.ng-untouched {
      @apply border-neutral-200 outline-neutral-200;
    }
    &.is-invalid {
      @apply invalid:border-rose-600 invalid:outline-rose-600;
    }
  }
  // Input Size
  @each $size, $sizeMap in $sizes {
    // Calculate the increased value
    .input-#{$size} {
      height: inherit;
      line-height: inherit;
      @apply px-#{map-get($sizeMap, "paddingX")} py-#{map-get($sizeMap, "paddingY")} text-#{map-get($sizeMap, "fontSize")};
    }
  }

  // Input Group
  .input-group {
    @apply inline-flex w-full flex-col items-start justify-start gap-2;
    label {
      @apply self-stretch text-lg font-semibold text-black;
    }
    .group {
      @include baseInput();
      @apply inline-flex items-center justify-start gap-2 self-stretch;
      @apply focus-within:-inset-1 focus-within:border-blue-400 focus-within:ring-1 focus-within:ring-inset focus-within:ring-blue-400;
      input,
      .input-field {
        @apply shrink grow basis-0 outline-0;
        @apply placeholder:text-neutral-500;
      }
      .input-icon {
        @apply relative h-4 w-4;
      }
      &.invalid {
        @apply border-rose-600 ring-rose-600;
        @apply hover:border-rose-600 hover:outline-rose-600;
      }
    }
    .error-msg,
    .invalid-feedback {
      @apply text-sm leading-none tracking-tight text-rose-600;
    }
  }
  // Input Group Size
  @each $size, $sizeMap in $sizes {
    // Calculate the increased value
    .input-group-#{$size} {
      .group {
        @apply px-#{map-get($sizeMap, "paddingX")} py-#{map-get($sizeMap, "paddingY")};
      }
      > input,
      .input-field {
        @apply px-#{map-get($sizeMap, "paddingX")} py-#{map-get($sizeMap, "paddingY")};
      }
    }
  }

  // Check Group
  @mixin baseCheckbox() {
    @apply h-4 w-4 appearance-none rounded border border-neutral-500 bg-white;
    @apply checked:border-neutral-900 checked:bg-neutral-900;
    @apply disabled:border-neutral-100 disabled:bg-neutral-100;
  }
  input[type="checkbox"] {
    @include baseCheckbox();
    &:checked {
      @apply transition duration-300 ease-in-out;
      //background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E");
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8' fill='none'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9.04883 0.337856C9.41454 0.585785 9.51002 1.08324 9.26209 1.44895L5.19444 7.44895C5.0616 7.64489 4.8488 7.77194 4.61328 7.79592C4.37777 7.81989 4.14373 7.73833 3.97413 7.57317L0.841784 4.52285C0.525247 4.2146 0.518528 3.70811 0.826775 3.39158C1.13502 3.07504 1.64151 3.06832 1.95805 3.37657L4.40608 5.7605L7.93774 0.551115C8.18567 0.185406 8.68312 0.089927 9.04883 0.337856Z' fill='white'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 9px 10px;
      background-position: 2px 2px;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }
    $checkboxSize: 16;
    @each $size, $percentage in $sizes {
      // Calculate the increased value
      $checkboxSize: map-get(
        (
          sm: (
            "size": "12px",
            "bgSize": "9px 10px",
            "bgPosition": "0px 0px",
          ),
          base: (
            "size": "16px",
            "bgSize": "10px 10px",
            "bgPosition": "2px 2px",
          ),
          lg: (
            "size": "20px",
            "bgSize": "11px",
            "bgPosition": "3px 4px",
          ),
        ),
        $size
      );
      &.checkbox-#{$size} {
        @apply h-[#{map-get($checkboxSize, "size")}] w-[#{map-get($checkboxSize, "size")}];
        background-size: #{map-get($checkboxSize, "bgSize")};
        background-position: #{map-get($checkboxSize, "bgPosition")};
      }
    }
  }
  .input-check {
    @apply flex items-center;
    input[type="checkbox"] {
      @include baseCheckbox();
    }
    label {
      @apply ml-2 cursor-pointer text-sm text-neutral-900;
    }
    &.disabled {
      label {
        @apply text-neutral-400;
      }
    }
    @each $size, $percentage in $sizes {
      // TODO Checkbox sizes when it was in group
    }
  }

  // Switch Toggle
  

  // Radio
  @mixin baseRadio() {
    @apply h-[16px] w-[16px] appearance-none rounded-full border border-neutral-900 bg-white checked:bg-neutral-900;
    @apply disabled:border-neutral-100 disabled:bg-neutral-100;
  }
  input[type="radio"] {
    position: relative;
    @include baseRadio();
    &:checked {
      &::after {
        content: "";
        @apply absolute left-[0.20rem] top-[0.20rem] h-2 w-2 rounded-full bg-white;
      }
    }
  }
  .input-radio {
    @apply flex items-center;
    input[type="radio"] {
      @include baseRadio();
    }
    label {
      @apply ml-2 cursor-pointer text-sm text-neutral-900;
    }
    &.disabled {
      label {
        @apply text-neutral-400;
      }
    }
  }
}

// Formly Related CSS
formly-field,
formly-wrapper-form-field {
  @apply block;
}
formly-field-radio,
formly-field-multicheckbox {
  @apply w-full;
}
formly-repeat-section {
  @apply w-full;
  input {
    @apply w-full;
  }
}

.feedback-input-group {
  @apply border-b border-neutral-200 pb-3.5;
  &.readonly {
    input,
    textarea,
    select,
    .select2 button {
      @apply border-transparent bg-transparent px-0;
      @apply disabled:bg-transparent;
    }
    .select2 button {
      @apply p-0;
      svg {
        @apply hidden;
      }
    }
    textarea {
      @apply overflow-y-scroll border border-neutral-100 p-4;
      @include scrollBar();
    }
  }
}

.image-placeholder {
  @apply bg-neutral-50;
}

// Uppy
formly-field-file {
  @apply w-full;
}

formly-repeat-section{
  .disabled-input-outline{
     @apply focus:ring-offset-0 focus:shadow-none focus:ring-offset-transparent;
     --tw-ring-shadow: none !important;
  }
}