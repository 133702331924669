@mixin scrollBar() {
    &::-webkit-scrollbar {
        @apply bg-neutral-100 h-[5px] w-[5px] rounded-full;
    }
    &::-webkit-scrollbar-track {
        @apply bg-neutral-100 rounded-full;
    }
    &::-webkit-scrollbar-thumb {
        @apply bg-neutral-400 rounded-full;
    }
}
