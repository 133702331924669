$colors: "primary", "secondary", "slate", "red", "green", "dark";

.badge {
  @apply rounded border bg-white p-2 text-xs  whitespace-nowrap;
}
@each $color in $colors {
  .badge-#{$color} {
    @apply border-#{$color+"-100"};
  }
  .badge-solid-#{$color} {
    @apply bg-#{$color+"-100"} border-#{$color+"-100"} text-#{$color};
  }

  .badge-xs {
    @apply py-1;
  }
}
