// Components
@tailwind components;

@layer components {
    @import "_mixin";
    @import "_buttons";
    @import "_inputs";
    @import "_selects";
    @import "_tags";
    @import "_datagrid";
    @import "_tooltip";
    @import "_dropdown";
    @import "_modal";
    @import "_pagination";
    @import "_badge";
    @import "_other";
    @import "_editor";
}
