@tailwind base;
@layer base {
    html {
        @apply font-nunito;
        @apply text-neutral-900;
    }
    h1,
    .h1 {
        @apply text-5xl font-semibold;
    }
    h2,
    .h2 {
        @apply text-4xl font-semibold;
    }
    h3,
    .h3 {
        @apply text-3xl font-semibold;
    }
    h4,
    .h4 {
        @apply text-2xl font-semibold;
    }
    h5,
    .h5 {
        @apply text-xl font-semibold;
    }
    h6,
    .h6 {
        @apply text-lg font-semibold;
    }
    p {
        @apply mb-4;
    }
}
