.tooltip{
    @apply block absolute z-[999] max-w-xs;
    div{
        @apply relative z-10 -mr-3 mt-1 px-4 py-2 inline-block bg-neutral-50 font-normal not-italic text-sm shadow-md rounded-[4px] text-neutral-900;
        &::before{
            // content:'';
            // @apply w-4 h-4 origin-top-left -rotate-45 bg-neutral-50 rounded-sm absolute top-2;
            content: "";
            top: -12px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 9px solid red;
            @apply absolute w-4 h-4 border-b-neutral-50;
        }
    }
    &.top{
        div{
            &::before{
               @apply top-full rotate-180;
            }
        }
    }
}