.datagrid-table{
    table {
        border-collapse: separate;
        border-spacing: 0 0px;
    }
    thead{
        @apply border-b bg-neutral-50 border-neutral-200 text-neutral-500;
        th{
            @apply py-3 px-3 border-b border-neutral-200 bg-neutral-50;
        }
        .sortable{
            @apply flex items-center gap-3;
        }
        .col-name{
            @apply text-xs font-normal truncate;
        }
    }
    tbody{
        tr{
            @apply rounded-[4px] bg-white hover:bg-neutral-50 text-sm;
            td{
                @apply py-[10px] px-4 border-b border-neutral-200;
                // &:first-child{
                //     @apply border-l border-neutral-100 rounded-l-md;
                // }
                // &:last-child{
                //     @apply border-r border-neutral-100 rounded-r-md;
                // }
            }
        }
    }
    &::-webkit-scrollbar {
        @apply bg-primary-200 h-[5px] w-[5px] rounded-full;
    }
    &::-webkit-scrollbar-track {
        @apply bg-primary-200 rounded-full;
    }
    &::-webkit-scrollbar-thumb {
        @apply bg-primary-500 rounded-full;
    }
    &.no_pagination{
        tr:last-child{
            @apply rounded-b-lg;
            td{
                @apply border-none;
                &:first-child{
                    @apply rounded-bl-lg;
                }
                &:last-child{
                    @apply rounded-br-lg;
                }
            }
        }
    }
}