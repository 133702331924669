.menu-dropdown {
  @apply relative;
  &.active {
    @apply flex flex-col;
  }
  .dropdown-container{
    @apply visible z-[200] mt-px translate-y-0 border bg-white opacity-100 transition-all duration-200 ease-in-out;
    @apply text-sm text-neutral-600;
    @apply top-full min-w-[200px] lg:absolute lg:border-neutral-200;
    @apply rounded-lg shadow-lg;
  }
  &.right{
    .dropdown-container{
      @apply right-0;
    }
    .sub-item{
      @apply -left-[105%];
      right: inherit;
    }
    .sub-arrow{
      @apply rotate-180 mt-0 -mb-1;
    }
  }
}
.dropdown-items{
  @apply p-1;
  li{
    @apply border-b border-neutral-200 py-[2px];
    a{
      @apply flex items-center gap-2 px-4 py-2 rounded text-neutral-900;
      @apply hover:bg-neutral-100
    }
    svg-icon{
      @apply block w-[18px] h-[18px];
    }
    &:last-child{
      @apply border-none;
    }
    &:hover{
      .sub-item{
        @apply block top-0;
      }
    }
    .sub-arrow{
      @apply -mr-2 -mt-1 ml-auto w-6;
    }
  }
}
.sub-item{
  @apply hidden top-0 left-full;
}

.content{
  @include scrollBar();
}