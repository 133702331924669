/*Uppy uploader styles*/
@import "@uppy/core/dist/style.css";
@import "@uppy/dashboard/dist/style.css";
// @import "@uppy/drag-drop/dist/style.css";

.uppy-Dashboard-AddFiles-info {
  svg {
    @apply w-3;
  }
}
.uppy-Root,
.uppy-Dashboard,
.uppy-Dashboard-inner {
  @apply h-full w-full #{!important};
}

.uppy-StatusBar-spinner {
  @apply h-[14px] w-[14px];
}

uppy-status-bar{
  .uppy-Root{
    @apply max-h-[50px];
  }
}

.uppyUpload {
  // Drag and Drop
  .uppyUpload-dragdrop {
    @apply block rounded-lg border border-neutral-200 bg-neutral-50 p-6;
    .uppy-DragDrop-container {
      @apply min-h-[150px] text-center hover:bg-primary-100 cursor-pointer rounded-md;
      svg {
        @apply hidden;
      }
      .uppy-DragDrop-label {
        @apply text-primary text-sm font-semibold;
      }
      .uppy-DragDrop-browse {
        @apply text-sm font-semibold text-neutral-700;
      }
      .uppy-DragDrop-note {
        @apply mt-2 block text-sm text-neutral-500;
      }
    }
  }
  .uppyUpload-dragdrop {
    @apply flex flex-col gap-3 rounded-lg border border-neutral-200 bg-neutral-50 p-6;
    .file-icon {
      @apply text-primary mx-auto flex h-14 w-14 rounded-full bg-white shadow-lg;
      svg-icon {
        @apply m-auto block w-8;
      }
    }
    .status {
      @apply text-center;
    }
    .uploading {
      @apply text-lg font-bold;
    }
    .estimate {
      @apply mt-1 text-xs text-neutral-500;
      span {
        @apply text-neutral-700;
      }
    }
    .progress-status {
      @apply border border-neutral-100 bg-white p-3;
      svg-icon {
        @apply m-auto block w-4;
      }
      .progress-container {
        @apply mt-2 h-[5px] overflow-hidden rounded-full bg-neutral-100;
        .progress {
          @apply h-[5px] max-w-full rounded-full bg-teal-600;
        }
      }
    }
  }
  // Default
  .uppyUpload-default-container {
    @apply w-full items-center rounded-md border-neutral-50 bg-neutral-50 p-2;
    .uppyUpload-default {
      svg {
        @apply hidden;
      }
      .uppy-DragDrop-label {
        @apply text-sm;
      }
      button {
        @apply px-3 py-1;
      }
      .uppy-DragDrop-note {
        @apply hidden;
      }
    }
    .progress-container {
      @apply h-[4px] overflow-hidden rounded-full bg-neutral-100;
      .progress {
        @apply bg-primary h-[4px] max-w-full rounded-full;
      }
    }
  }
}
