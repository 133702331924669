.modal-container{
    &.right{
        @apply justify-end;
        .modal-box{
            @apply rounded-r-none m-0 h-screen;
        }
    }
    &.left{
        @apply justify-start;
        .modal-box{
            @apply rounded-l-none m-0 h-screen;
        }
    }
}