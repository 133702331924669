@import "prosemirror-view/style/prosemirror.css";
@import "prosemirror-tables/style/tables.css";
@import 'prism-themes/themes/prism-nord.css';
@import "@milkdown/theme-nord/style.css";

.prose {
  white-space: normal !important;
  min-height: fit-content;
  padding-left: 2.5rem !important;
}

// Text Format
.textFormat {
  ul{
    @apply my-4 ml-6 mr-0 list-disc;
    li{
      &::marker{
        @apply text-slate-500;
      }
    }
    p{
      @apply m-0;
    }
  }
  ol{
    @apply my-4 ml-6 mr-0 list-decimal;
    li{
      &::marker{
        @apply text-slate-500;
      }
    }
    p{
      @apply m-0;
    }
  }
}
markdown, .markdown{
  @apply textFormat;
}
.textEditor{
  .milkdown{
    @apply textFormat;
    // ::-moz-selection { /* Code for Firefox */
    //   color: red;
    //   background: yellow;
    // }
    // ::selection {
    //   color: red;
    //   background: yellow;
    // }
  }
}
.no-highlight {
  user-select: none;
  @apply cursor-pointer;
}

code{
  color: #333333 !important;
}

// Editor 
.textEditor{
  @apply transition-all h-[250px] w-full rounded-lg border border-slate-200 hover:border-slate-700 px-4 py-3;
  @apply flex flex-col;
  .editorContainer{
    @apply overflow-y-auto ;
  }
  .milkdown{
    @apply w-full h-full;
    .editor{
      @apply w-full h-full outline-none;
    }
  }
  &.full{
    @apply h-[450px];
  }
  .toolbar{
    @apply pb-1 border-b border-slate-100;
    .text-formatter{
        svg{ 
            @apply cursor-pointer;
            @apply text-slate-400 hover:text-slate-700;
        }
    }
  }
}